/* eslint-disable quotes */
import { makeStyles } from '@material-ui/core/styles';

const teal = '#006E90';
const orange = '#F18F01';
const white = '#FFFFFF';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  // NAVBAR
  navbar: {
    height: '80px',
    [theme.breakpoints.up(900)]: {
      height: '120px',
    },
    backgroundColor: teal,
    boxShadow: '0px 6px 10px 0px rgba(0, 0, 0, 0.25)',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    width: '100vw',
    display: 'flex',
  },
  navbarContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up(900)]: {
      padding: '0px 50px',
      maxWidth: '1280px',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.up(1280)]: {
      margin: '0 auto',
    },
  },
  navbarLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minWidth: '240px',
    padding: '0px 25px',
    '& img': {
      maxHeight: '60px',
    },
    [theme.breakpoints.up(900)]: {
      maxWidth: '300px',
    },
  },
  navbarItems: {
    display: 'none',
    [theme.breakpoints.up(900)]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      paddingLeft: '50px',
    },
    [theme.breakpoints.up(1280)]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      paddingLeft: '150px',
    },
  },
  navbarItem: {
    textTransform: 'uppercase',
    color: white,
    fontSize: '22px',
    '& a': {
      color: white,
      textDecoration: 'none',
    },
    '& a:link': {
      color: white,
      textDecoration: 'none',
    },
    '& a:visited': {
      color: white,
      textDecoration: 'none',
    },
    '& a:hover': {
      color: orange,
    },
  },
  navbarItemHighlighted: {
    textTransform: 'uppercase',
    color: orange,
    fontSize: '22px',
    fontWeight: 700,
    '& a': {
      color: orange,
      textDecoration: 'none',
    },
    '& a:link': {
      color: orange,
      textDecoration: 'none',
    },
    '& a:visited': {
      color: orange,
      textDecoration: 'none',
    },
  },
  // PAGE
  page: {
    display: 'flex',
    flexDirection: 'column',
  },
  // BANNER
  banner: {
    minHeight: '100vh',
    width: '100vw',
    background: `linear-gradient(rgba(0, 110, 144, 0.85), rgba(0, 110, 144, 0.85)), url('https://storage.googleapis.com/sipos-public/landing/banner_background.jpg')`,
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  bannerContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up(900)]: {
      height: '100vh',
      paddingTop: '120px',
      flexDirection: 'row',
    },
  },
  bannerLogo: {
    '& img': {
      height: '250px',
      maxWidth: '250px',
      [theme.breakpoints.up(300)]: {
        height: '275px',
        maxWidth: '275px',
      },
      [theme.breakpoints.up(326)]: {
        height: '300px',
        maxWidth: '300px',
      },
    },
    padding: '125px 0px 25px 0px',
    [theme.breakpoints.up(900)]: {
      padding: '0px 75px 0px 0px',
    },
  },
  bannerText: {
    padding: '50px 15px 50px 15px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    [theme.breakpoints.up(450)]: {
      maxWidth: '350px',
      padding: '25px 0px 0px 0px',
    },
    [theme.breakpoints.up(900)]: {
      gap: '25px',
      maxWidth: '425px',
      padding: '0px 0px 0px 75px',
    },
  },
  bannerTextTitle: {
    display: 'flex',
    flexDirection: 'column',
    height: '100px',
    [theme.breakpoints.up(900)]: {
      height: '120px',
    },
  },
  bannerTextTitleTop: {
    lineHeight: '30px',
    fontSize: '35px',
    [theme.breakpoints.up(300)]: {
      fontSize: '40px',
    },
    [theme.breakpoints.up(900)]: {
      fontSize: '50px',
    },
    fontWeight: 700,
    color: white,
    height: '60px',
  },
  bannerTextTitleBottom: {
    lineHeight: '30px',
    fontSize: '35px',
    [theme.breakpoints.up(300)]: {
      fontSize: '40px',
    },
    [theme.breakpoints.up(900)]: {
      fontSize: '50px',
    },
    fontWeight: 700,
    color: orange,
    height: '60px',
  },
  bannerTextText: {
    lineHeight: '30px',
    fontSize: '20px',
    [theme.breakpoints.up(900)]: {
      fontSize: '25px',
    },
    fontWeight: 700,
    color: white,
    '& span': {
      color: orange,
    },
  },
  // WHY
  why: {
    minHeight: '100vh',
    width: '100vw',
    backgroundColor: white,
  },
  whyContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '100px 10px',
  },
  whyTitle: {
    height: '100px',
    lineHeight: '30px',
    fontSize: '35px',
    [theme.breakpoints.up(300)]: {
      fontSize: '40px',
    },
    [theme.breakpoints.up(900)]: {
      fontSize: '50px',
    },
    fontWeight: 700,
    color: teal,
    '& span': {
      color: orange,
      [theme.breakpoints.up(326)]: {
        paddingLeft: '12px',
      },
    },
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    [theme.breakpoints.up(326)]: {
      flexDirection: 'row',
      gap: '0px',
    },
    alignItems: 'center',
  },
  whyInfo: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(900)]: {
      flexDirection: 'row',
    },
  },
  whyInfoItem: {
    minHeight: '300px',
    width: '300px',
    padding: '25px 0px',
    [theme.breakpoints.up(900)]: {
      padding: '75px 0px 25px 0px',
      margin: '0px 50px',
    },
  },
  whyInfoItemIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '150px',
    '& svg': {
      color: teal,
      fontSize: '128px',
    },
  },
  whyInfoItemTitle: {
    display: 'flex',
    flexDirection: 'column',
  },
  whyInfoItemTitleTop: {
    textAlign: 'center',
    color: orange,
    fontSize: '30px',
    fontWeight: 700,
  },
  whyInfoItemTitleBottom: {
    textAlign: 'center',
    color: teal,
    fontSize: '30px',
    fontWeight: 700,
  },
  whyInfoItemText: {
    paddingTop: '20px',
    textAlign: 'center',
    color: teal,
    fontSize: '20px',
    fontWeight: 400,
    margin: '0 auto',
  },
  // HOW
  how: {
    minHeight: '100vh',
    width: '100vw',
    background: `linear-gradient(rgba(0, 110, 144, 0.85), rgba(0, 110, 144, 0.85)), url('https://storage.googleapis.com/sipos-public/landing/how_background.jpg')`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  howContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '100px 25px',
  },
  howTitle: {
    color: white,
    fontSize: '35px',
    [theme.breakpoints.up(300)]: {
      fontSize: '40px',
    },
    [theme.breakpoints.up(900)]: {
      fontSize: '50px',
    },
    fontWeight: 700,
    padding: '25px 25px',
    textAlign: 'center',
    '& span': {
      paddingLeft: '4px',
      color: orange,
    },
  },
  howSubtitle: {
    fontSize: '25px',
    [theme.breakpoints.up(900)]: {
      fontSize: '40px',
    },
    color: white,
    maxWidth: '1280px',
    textAlign: 'center',
    padding: '25px 25px',
  },
  howInfo: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(900)]: {
      flexDirection: 'row',
    },
  },
  howInfoItem: {
    minHeight: '300px',
    width: '300px',
    padding: '25px 0px',
    [theme.breakpoints.up(900)]: {
      padding: '75px 0px 25px 0px',
      margin: '0px 50px',
    },
  },
  howInfoItemIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '150px',
    '& svg': {
      color: white,
      fontSize: '128px',
    },
  },
  howInfoItemTitle: {
    display: 'flex',
    flexDirection: 'column',
  },
  howInfoItemTitleTop: {
    textAlign: 'center',
    color: white,
    fontSize: '30px',
    fontWeight: 700,
  },
  howInfoItemTitleBottom: {
    textAlign: 'center',
    color: orange,
    fontSize: '30px',
    fontWeight: 700,
  },
  howInfoItemText: {
    paddingTop: '20px',
    textAlign: 'center',
    color: white,
    fontSize: '20px',
    fontWeight: 400,
    margin: '0 auto',
  },
  // CONTACT
  contact: {
    height: '100vh',
    width: '100vw',
    backgroundColor: white,
  },
  contactContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: '0px 25px',
  },
  contactTitleTop: {
    lineHeight: '30px',
    fontSize: '40px',
    [theme.breakpoints.up(900)]: {
      fontSize: '50px',
    },
    fontWeight: 700,
    height: '60px',
    color: teal,
  },
  contactTitleBottom: {
    lineHeight: '30px',
    fontSize: '40px',
    [theme.breakpoints.up(900)]: {
      fontSize: '50px',
    },
    fontWeight: 700,
    height: '60px',
    color: orange,
  },
  contactText: {
    textAlign: 'center',
    paddingTop: '20px',
    fontSize: '25px',
    [theme.breakpoints.up(900)]: {
      fontSize: '30px',
    },
    fontWeight: 400,
    color: teal,
    maxWidth: '725px',
  },
  contactButton: {
    margin: '50px 0px',
    height: '60px',
    width: '200px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: teal,
    borderRadius: '10px',
    '& a': {
      color: white,
      textDecoration: 'none',
      fontSize: '20px',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& a:link': {
      color: white,
      textDecoration: 'none',
      fontSize: '20px',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& a:visited': {
      color: white,
      textDecoration: 'none',
      fontSize: '20px',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  // FOOTER
  footer: {
    height: '1px',
    width: '100vw',
    [theme.breakpoints.up(900)]: {
      height: '325px',
    },
  },
  footerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // [theme.breakpoints.up(900)]: {
    //   flexDirection: 'row',
    // },
  },
  footerLogo: {
    display: 'flex',
    padding: '80px 40px',
    '& img': {
      height: '180px',
      maxWidth: '180px',
    },
  },
  footerItems: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up(900)]: {
      flexDirection: 'row',
    },
  },
  footerItem: {
    height: '200px',
    padding: '25px 0px 25px 50px',
    width: '100%',
    background: 'red',
  },
}));

export default useStyles;
