/* eslint-disable max-len */
import React from 'react';

import {
  MoreTime as MoreTimeIcon,
  Timeline as TimelineIcon,
  ThumbUp as ThumbUpIcon,
  PhoneIphone as PhoneIcon,
  ShoppingCart as ShoppingCartIcon,
  CreditCard as CreditCardIcon,
} from '@mui/icons-material';

import useStyles from './styles';

function Landing() {
  const styles = useStyles();

  return (
    <div id="inicio" className={styles.root}>
      <div className={styles.navbar}>
        <div className={styles.navbarContent}>
          <div className={styles.navbarLogo}>
            <img src="https://storage.googleapis.com/sipos-public/landing/logo_horizontal_blanco.svg" alt="SIPOS" />
          </div>
          <div className={styles.navbarItems}>
            <div className={styles.navbarItemHighlighted}><a href="#inicio">inicio</a></div>
            <div className={styles.navbarItem}><a href="#porque-sipos">¿por qué sipos?</a></div>
            <div className={styles.navbarItem}><a href="#como-funciona">¿cómo funciona?</a></div>
            <div className={styles.navbarItem}><a href="#contacto">contáctanos</a></div>
          </div>
        </div>
      </div>
      <div className={styles.page}>
        <div className={styles.banner}>
          <div className={styles.bannerContent}>
            <div className={styles.bannerLogo}>
              <img src="https://storage.googleapis.com/sipos-public/landing/logo_blanco.svg" alt="SIPOS" />
            </div>
            <div className={styles.bannerText}>
              <div className={styles.bannerTextTitle}>
                <div className={styles.bannerTextTitleTop}>¡Atrévete y</div>
                <div className={styles.bannerTextTitleBottom}>únete a SIPOS!</div>
              </div>
              <div className={styles.bannerTextText}>Un software de ventas <span>GRATIS</span> para mantener las finanzas de tu negocio en orden</div>
              <div className={styles.bannerTextText}>¡Crea tu cuenta en línea y comienza inmediatamente!</div>
            </div>
          </div>
        </div>
        <div id="porque-sipos" className={styles.why}>
          <div className={styles.whyContent}>
            <div className={styles.whyTitle}>¿Por qué <span>SIPOS?</span></div>
            <div className={styles.whyInfo}>
              <div className={styles.whyInfoItem}>
                <div className={styles.whyInfoItemIcon}>
                  <MoreTimeIcon />
                </div>
                <div className={styles.whyInfoItemTitle}>
                  <div className={styles.whyInfoItemTitleTop}>Gana tiempo</div>
                  <div className={styles.whyInfoItemTitleBottom}>todos los días</div>
                </div>
                <div className={styles.whyInfoItemText}>Cuando esta todo ordenado, tienes mas tiempo para manejar tu negocio. SIPOS! organiza tus ingresos, gastos, pagos y clientes.</div>
              </div>
              <div className={styles.whyInfoItem}>
                <div className={styles.whyInfoItemIcon}>
                  <TimelineIcon />
                </div>
                <div className={styles.whyInfoItemTitle}>
                  <div className={styles.whyInfoItemTitleTop}>Crecimiento</div>
                  <div className={styles.whyInfoItemTitleBottom}>a simple vista</div>
                </div>
                <div className={styles.whyInfoItemText}>Obtén una imagen clara del crecimiento de tu negocio al instante. SIPOS! ordena tus finanzas, brindándote una visión general clara de tu negocio.</div>
              </div>
              <div className={styles.whyInfoItem}>
                <div className={styles.whyInfoItemIcon}>
                  <ThumbUpIcon />
                </div>
                <div className={styles.whyInfoItemTitle}>
                  <div className={styles.whyInfoItemTitleTop}>Negocio</div>
                  <div className={styles.whyInfoItemTitleBottom}>con facilidad</div>
                </div>
                <div className={styles.whyInfoItemText}>Confía en SIPOS! para cerrar con facilidad y precisión cada mes, teniendo detalles de cada transacción ordenados en la aplicación durante todo el mes.</div>
              </div>
            </div>
          </div>
        </div>
        <div id="como-funciona" className={styles.how}>
          <div className={styles.howContent}>
            <div className={styles.howTitle}>¿Cómo <span>funciona?</span></div>
            <div className={styles.howSubtitle}>SIPOS! es la mejor manera de administrar el dinero en tu negocio. Realiza un seguimiento de tus ventas, administra tus gastos, potencia a tus clientes y mantén un control fácil de tu inventario.</div>
            <div className={styles.howInfo}>
              <div className={styles.howInfoItem}>
                <div className={styles.howInfoItemIcon}>
                  <PhoneIcon />
                </div>
                <div className={styles.howInfoItemTitle}>
                  <div className={styles.howInfoItemTitleTop}>Crea</div>
                  <div className={styles.howInfoItemTitleBottom}>tu cuenta</div>
                </div>
                <div className={styles.howInfoItemText}>Regístrate y crea tu cuenta completamente GRATIS. Sólo necesitas un número de teléfono.</div>
              </div>
              <div className={styles.howInfoItem}>
                <div className={styles.howInfoItemIcon}>
                  <ShoppingCartIcon />
                </div>
                <div className={styles.howInfoItemTitle}>
                  <div className={styles.howInfoItemTitleTop}>Carga tus</div>
                  <div className={styles.howInfoItemTitleBottom}>productos y precios</div>
                </div>
                <div className={styles.howInfoItemText}>Sigue las simples instrucciones para cargar la información de los productos que quieres vender en tu negocio.</div>
              </div>
              <div className={styles.howInfoItem}>
                <div className={styles.howInfoItemIcon}>
                  <CreditCardIcon />
                </div>
                <div className={styles.howInfoItemTitle}>
                  <div className={styles.howInfoItemTitleTop}>Tu negocio ordenado</div>
                  <div className={styles.howInfoItemTitleBottom}>y listo para vender</div>
                </div>
                <div className={styles.howInfoItemText}>Empieza a vender con SIPOS! y ya tendrás completo control de tus productos, inventario, clientes, ventas y gastos.</div>
              </div>
            </div>
          </div>
        </div>
        <div id="contacto" className={styles.contact}>
          <div className={styles.contactContent}>
            <div className={styles.contactTitleTop}>¿Te interesa?</div>
            <div className={styles.contactTitleBottom}>¡Contáctanos!</div>
            <div className={styles.contactText}>Escríbenos aquí y nos pondremos en contacto contigo a la brevedad para poder trabajar juntos</div>
            <div className={styles.contactButton}>
              <a href="https://wa.me/+56993495075">Contáctanos aquí</a>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.footerContent}>
            <div className={styles.footerLogo}>
              <img src="https://storage.googleapis.com/sipos-public/landing/logo_blanco.svg" alt="SIPOS" />
            </div>
            {/* <div className={styles.footerItems}>
              <div className={styles.footerItem}>CONTACT</div>
              <div className={styles.footerItem}>LINKS</div>
              <div className={styles.footerItem}>RRSS</div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
